import React from "react";
import { Avatar, Popover } from "antd";
import { Link } from "react-router-dom";
import AuthService from "../../../src/services/auth.service";

const UserProfile = () => {
  const currentUser = AuthService.getCurrentUser();
  let fname = "";
  let lname = "";
  if (currentUser) {
    fname = currentUser.firstname;
    lname = currentUser.lastname;
  }

  const logout = () => {
    AuthService.logout();
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <Link to="/login">
        <li onClick={logout}>Logout</li>
      </Link>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
      >
        <Avatar
          className="gx-size-40 gx-pointer gx-mr-3"
          size="large"
          style={{ color: "#2e2e2e", backgroundColor: "#fff" }}
        >
          {fname[0] + lname[0]}
        </Avatar>
        <span className="gx-avatar-name">
          {fname + " " + lname}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </Popover>
    </div>
  );
};

export default UserProfile;
