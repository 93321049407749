import React from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
// import IntlMessages from "../../util/IntlMessages";
import { useSelector } from "react-redux";

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const pathname = useSelector(({ common }) => common.pathname);

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserProfile />
          <AppsNavigation />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <Menu.Item key="dashboard">
              <Link to="/dashboard">
                <i className="icon icon-widgets" />
                <span>Dashboard</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="email">
              <Link to="/email">
                <i className="icon icon-email" />
                <span>Email</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="price">
              <Link to="/price">
                <i className="icon icon-pricing-table" />
                <span>Price</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="vendor">
              <Link to="/vendor">
                <i className="icon icon-company" />
                <span>Vendor</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="program">
              <Link to="/program">
                <i className="icon icon-apps" />
                <span>Program</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="cust-program">
              <Link to="/cust-program">
                <i className="icon icon-crm" />
                <span>Cust Programs</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="parser-explorer">
              <Link to="/parser-explorer">
                <i className="icon icon-product-list" />
                <span>Parser Explorer</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="parser-builder">
              <Link to="/parser-builder">
                <i className="icon icon-ckeditor" />
                <span>Parser Builder</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="price-transport">
              <Link to="/price-transport">
                <i className="icon icon-mail-open" />
                <span>Price Transport</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="cust-upload-programs">
              <Link to="/cust-upload-programs">
                <i className="icon icon-auth-screen" />
                <span>Cust Upload Programs</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="cust-prices">
              <Link to="/cust-prices">
                <i className="icon icon-table" />
                <span>Cust Uploads</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="customers">
              <Link to="/customers">
                <i className="icon icon-user-o" />
                <span>Customers</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="customer-users">
              <Link to="/customer-users">
                <i className="icon icon-profile" />
                <span>Customer Users</span>
              </Link>
            </Menu.Item>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default React.memo(SidebarContent);
