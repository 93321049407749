import React from "react";
import { Route, Switch } from "react-router-dom";
import "./index.css";
import asyncComponent from "util/asyncComponent";
import { PrivateRoute } from "../_components/PrivateRoute";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route
        path={`${match.url}login`}
        component={asyncComponent(() => import("./Login"))}
      />
      <PrivateRoute
        path={`${match.url}dashboard`}
        component={asyncComponent(() => import("./Dashboard"))}
      />
      <PrivateRoute
        path={`${match.url}email`}
        component={asyncComponent(() => import("./Email"))}
      />
      <PrivateRoute
        path={`${match.url}price`}
        component={asyncComponent(() => import("./Price"))}
      />
      <PrivateRoute
        path={`${match.url}vendor`}
        component={asyncComponent(() => import("./Vendor"))}
      />
      <PrivateRoute
        path={`${match.url}program`}
        component={asyncComponent(() => import("./Program"))}
      />
      <PrivateRoute
        path={`${match.url}cust-program`}
        component={asyncComponent(() => import("./CustProgram"))}
      />
      <PrivateRoute
        path={`${match.url}parser-explorer`}
        component={asyncComponent(() => import("./PriceImports"))}
      />
      <PrivateRoute
        path={`${match.url}parser-builder`}
        component={asyncComponent(() => import("./ParserBuilder"))}
      />
      <PrivateRoute
        path={`${match.url}price-transport`}
        component={asyncComponent(() => import("./PriceTransport"))}
      />
      <PrivateRoute
        path={`${match.url}cust-upload-programs`}
        component={asyncComponent(() => import("./CustUploadPrograms"))}
      />
      <PrivateRoute
        path={`${match.url}cust-prices`}
        component={asyncComponent(() => import("./CustPrices"))}
      />
      <PrivateRoute
        path={`${match.url}customers`}
        component={asyncComponent(() => import("./Customers"))}
      />
      <PrivateRoute
        path={`${match.url}customer-users`}
        component={asyncComponent(() => import("./CustomerUsers"))}
      />
    </Switch>
  </div>
);

export default App;
